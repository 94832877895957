<template>
  <div class="page-content-inner">
    <h3 class="mb-4">{{ $t('certification.my_certification') }}</h3>
    <nav class="responsive-tab mb-4">
      <li class="uk-active">
        <!-- <router-link :to="{ name: 'User.my-certification' }">
          <span>{{ $t('certification.education_certification') }}</span>
        </router-link> -->
      </li>
      <li><a href="#">{{ $t('certification.add_my_education_certification') }}</a></li>
    </nav>
    <div class="section-header pb-0 mb-4">
      <div class="section-header-left">
        <input v-model="search" :placeholder="$t('certification.search_certification')" class="mt-3" type="text"
          @input="isTyping = true">
      </div>
      <!-- <div class="section-header-right">
        <select v-model="order_by" class=" ml-3 mb-0" @change="applyFilter">
          <option value="date">{{ $t('certification.order_date') }}</option>
          <option value="name">{{ $t('certification.order_name') }}</option>
        </select>
      </div> -->
    </div>
    <div>
    </div>
    <div uk-grid>
      <div class="uk-width-5-5@m">
        <template v-for="(certification, index) in certifications">
          <div class="uk-card-default rounded mb-3">
            <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
              <h4 class="mb-0"><i class="uil-medal"></i>
                <!-- {{ certification }} -->
                {{ certification.certificate?.name }} ( {{ certification?.unique_certificate_id }} )</h4>
              <!-- <a v-if="certification.content" uk-tooltip="title:Eğitime Git; pos: left"
                @click="handleOnClickCartEducation(certification.content.id)"> <i class="icon-feather-external-link"></i>
              </a> -->
            </div>
            <hr class="m-0">
            <div class="uk-child-width-1-5@s uk-grid-small p-4" uk-grid>
              <div>
                <h6 class="uk-text-bold"> {{ $t('general.assignment_name') }}</h6>
                <p> {{ certification.assignmentable?.name }}</p>
              </div>
              <div>
                <h6 class="uk-text-bold"> {{ $t('general.content_title') }}</h6>
                <p> {{ certification.contentable?.title }}</p>
              </div>
              <div>
                <h6 class="uk-text-bold"> {{ $t('certification.get_date') }}</h6>
                <p> {{ fixDate(certification.created_at) }}</p>
              </div>
              <div>
                <h6 class="uk-text-bold"> {{ $t('certification.end_date') }}</h6>
                <p> {{ certification.certificate?.expireable == 0 ? "Süresiz" :
                  fixDate(certification.certificate?.end_date) }} </p>
              </div>
              <div>
                <div id="assignment_certificate_modal" class="uk-modal-full" style="z-index: 999999999" uk-modal>
                  <div class="uk-modal-dialog h-100 bggray d-flex flex-column justify-content-between">
                    <div class="uk-modal-header d-flex justify-content-end bggray">
                      <button class="btn btn-animated btn-success btn-animated-x uk-first-column mr-1" type="button"
                        @click="handleToDownloadPdf(null)">
                        <span class="btn-inner--visible"><i class="uil-file-download"></i> {{
                          $t("certification.pdf") }}</span>
                        <span class="btn-inner--hidden">
                          <i class="uil-cloud-download"></i>
                        </span>
                      </button>
                      <button class="uk-button uk-button-default small uk-modal-close mr-1" type="button">
                        <i class="uil-times"></i> {{ $t("general.close") }}
                      </button>
                    </div>
                    <iframe :src="pdf_preview_link" style="width: 100%;height: 100%;">
                    </iframe>
                    <div class="uk-modal-footer uk-text-right bggray">
                      <button class="uk-button uk-button-default small uk-modal-close" type="button">
                        <i class="uil-times"></i> {{ $t("general.close") }}
                      </button>
                    </div>
                  </div>
                </div>
                <a class="btn btn-info btn-sm m-2 text-white" @click="showCertifcateModal(certification)">{{
                  $t('certification.preview') }}</a>
                <button class="btn btn-animated btn-sm btn-success btn-animated-x uk-first-column" type="button"
                  @click="handleToDownloadPdf(certification)">
                  <span class="btn-inner--visible">
                    <i class="uil-file-download"></i> {{ $t('certification.pdf') }}
                  </span>
                  <span class="btn-inner--hidden">
                    <i class="uil-cloud-download"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
    <!-- <div class="uk-flex uk-flex-center p-4">
      <button class="btn btn-info button_more" @click.prevent="itemsMoreButton">{{ $t('general.more') }}</button>
    </div> -->
  </div>
</template>
<script>

import { GET_ITEMS } from "@/core/services/store/REST.module";
import moment from "moment";
import 'moment/locale/tr'
import Pagination from "@/assets/components/Pagination";
export default {
  name: "MyCertification",
  components: { Pagination },
  data() {
    return {
      pagination: null,
      certificationsUrl: 'api/get-certificates',
      certifications: [],
      search: '',
      order_by: 'name',
      sort: 'desc',
      isTyping: false,
      searchResult: [],
      page: 1,
      perPage: 10,
      pdf_preview_link: '',
      certificate_download_link: null,
    };
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.applyFilter()
    },
    handleToDownloadPdf(value) {
      if (value)
        this.certificate_download_link = value.certificate_pdf_download_link;
      window.open(this.certificate_download_link, "_blank");
      UIkit.modal("#assignment_certificate_modal").hide();
    },

    handleOnClickCartEducation(value) {
      this.$router.push({ name: 'user.eduction-detail', params: { id: value } })
    },

    showCertifcateModal(value) {
      this.pdf_preview_link = value.certificate_pdf_preview_link;
      this.certificate_download_link = value.certificate_pdf_download_link;
      UIkit.modal("#assignment_certificate_modal").show();
    },
    itemsMoreButton() {
      let self = this;
      let pageNumber = null;
      if (this.certifications.last_page === this.page) {
        return;
      } else {
        pageNumber = this.page += 1;
        this.$store.dispatch(GET_ITEMS, {
          url: this.certificationsUrl,
          acceptPromise: false,
          filters: {
            'page': pageNumber,
            'perPage': this.perPage,
            'search': this.search,
            'order_by': this.order_by,
          }
        }).then(result => {
          if (result.status) {
            for (let i = 0; i < result.data.data.length; i++) {
              self.certifications.data.push(
                result.data.data[i],
              )
            }
          }
        })
      }
    },
    fixDate(value) {
      return moment(value).locale('tr').format('LL');
    },
    applyFilter: function (search) {
      let self = this;
      if (this.search) {
        self.certifications = [];
        self.page = 1;
      }
      this.$store.dispatch(GET_ITEMS, {
        url: this.certificationsUrl,
        filters: {
          'page': this.page,
          'perPage': this.perPage,
          'search': this.search,
          'order_by': this.order_by,
        },
        acceptPromise: false,
      }).then(result => {
        self.certifications = result.data.data;
        this.pagination = result.data;
      })
    }
  },
  mounted() {
    this.applyFilter();
  },
  watch: {
    search: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.applyFilter(this.search);
      }
    }
  }
}
</script>

<style scoped>
.button_more {
  border-radius: 50% !important;
  height: 70px;
  width: 70px;
  background-color: var(--backgroundColor) !important;

}
</style>
